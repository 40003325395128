export default {
  //get all HOMEWORKs
  GET_HOMEWORKS_REQUEST: "GET_HOMEWORKS_REQUEST",
  GET_HOMEWORKS_SUCCESS: "GET_HOMEWORKS_SUCCESS",
  GET_HOMEWORKS_FAIL: "GET_HOMEWORKS_FAIL",

  GET_HOMEWORKS_SUPER_ADMIN_REQUEST: "GET_HOMEWORKS_SUPER_ADMIN_REQUEST",
  GET_HOMEWORKS_SUPER_ADMIN_SUCCESS: "GET_HOMEWORKS_SUPER_ADMIN_SUCCESS",
  GET_HOMEWORKS_SUPER_ADMIN_FAIL: "GET_HOMEWORKS_SUPER_ADMIN_FAIL",

  GET_ADMIN_STUDENT_HOMEWORKS_REQUEST: "GET_ADMIN_STUDENT_HOMEWORKS_REQUEST",
  GET_ADMIN_STUDENT_HOMEWORKS_SUCCESS: "GET_ADMIN_STUDENT_HOMEWORKS_SUCCESS",
  GET_ADMIN_STUDENT_HOMEWORKS_FAIL: "GET_ADMIN_STUDENT_HOMEWORKS_FAIL",

  //edit HOMEWORKs
  EDIT_HOMEWORK_REQUEST: "EDIT_HOMEWORK_REQUEST",
  EDIT_HOMEWORK_SUCCESS: "EDIT_HOMEWORK_SUCCESS",
  EDIT_HOMEWORK_FAIL: "EDIT_HOMEWORK_FAIL",

  //create HOMEWORK
  CREATE_HOMEWORK_REQUEST: "CREATE_HOMEWORK_REQUEST",
  CREATE_HOMEWORK_SUCCESS: "CREATE_HOMEWORK_SUCCESS",
  CREATE_HOMEWORK_FAIL: "CREATE_HOMEWORK_FAIL",

  DELETE_HOMEWORK_REQUEST: "DELETE_HOMEWORK_REQUEST",
  DELETE_HOMEWORK_SUCCESS: "DELETE_HOMEWORK_SUCCESS",
  DELETE_HOMEWORK_FAIL: "DELETE_HOMEWORK_FAIL",

  PUBLISH_HOMEWORK_REQUEST: "PUBLISH_HOMEWORK_REQUEST",
  PUBLISH_HOMEWORK_SUCCESS: "PUBLISH_HOMEWORK_SUCCESS",
  PUBLISH_HOMEWORK_FAIL: "PUBLISH_HOMEWORK_FAIL",

  GET_HOMEWORK_REQUEST: "GET_HOMEWORK_REQUEST",
  GET_HOMEWORK_SUCCESS: "GET_HOMEWORK_SUCCESS",
  GET_HOMEWORK_FAIL: "GET_HOMEWORK_FAIL",

  ENABLE_HOMEWORK_REQUEST: "ENABLE_HOMEWORK_REQUEST",
  ENABLE_HOMEWORK_SUCCESS: "ENABLE_HOMEWORK_SUCCESS",
  ENABLE_HOMEWORK_FAIL: "ENABLE_HOMEWORK_FAIL",

  RESTRICT_HOMEWORK_REQUEST: "RESTRICT_HOMEWORK_REQUEST",
  RESTRICT_HOMEWORK_SUCCESS: "RESTRICT_HOMEWORK_SUCCESS",
  RESTRICT_HOMEWORK_FAIL: "RESTRICT_HOMEWORK_FAIL",
  //loading
  ADD_HOMEWORKS_LOADING: "ADD_HOMEWORKS_LOADING",
  PUBLISH_HOMEWORK_LOADING: "PUBLISH_HOMEWORK_LOADING",
  RESTRICT_HOMEWORK_LOADING: "RESTRICT_HOMEWORK_LOADING",
  ENABLE_HOMEWORK_LOADING: "ENABLE_HOMEWORK_LOADING",
  EDIT_HOMEWORK_QUESTION_REQUEST: "EDIT_HOMEWORK_QUESTION_REQUEST",
  EDIT_HOMEWORK_QUESTION_SUCCESS: "EDIT_HOMEWORK_QUESTION_SUCCESS",
  EDIT_HOMEWORK_QUESTION_FAIL: "EDIT_HOMEWORK_QUESTION_FAIL",
  FILTER_HOMEWORK_REQUEST: "FILTER_HOMEWORK_REQUEST",
  FILTER_HOMEWORK_SUCCESS: "FILTER_HOMEWORK_SUCCESS",
  FILTER_HOMEWORK_FAIL: "FILTER_HOMEWORK_FAIL",
};
