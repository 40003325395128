import { Box, Button, Container, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Layout from '../../../components/student/Layout/layout';
import { getExamsByIdRequest } from '../../../modules/students-modules/exams/Actions';

const ExameGuide = () => {
    const dispatch = useDispatch()
    const queryString = require('query-string');
    const parsed = queryString.parse(window.location.search);
    const examId = window.location.pathname.includes('student') ? window.location.pathname.split('/')[3] : window.location.pathname.split('/')[2]

    const exam = useSelector((state) => state.chapterExams.exam)
    const isLoading = useSelector((state) => state.chapterExams.isLoading)

    useEffect(() => {
        dispatch(getExamsByIdRequest(examId))
    }, [examId])
    return (
        <Layout color='main_1' bg='white' isLoading={isLoading}>
            <Container maxW='container.2xl' h='100%' my='100px' mb='165px' w='100%' >
                <VStack spacing={8} h='100%' w='100%' >
                    <Heading as='h3' color='main_1' textAlign='center' w='100%' textTransform='capitalize'>{parsed?.name}</Heading>
                    {exam?.timer == 1 && <VStack mt={{ base: '0', lg: '-55px !important' }} w='150px' h='90px' border='1px solid' color='main_1' borderColor='main_1' borderRadius='8px' alignSelf='flex-end'>
                        <Text fontSize='26px'>Timer</Text>
                        <Text fontSize='26px'>{exam?.time} : 00</Text>
                    </VStack>}
                    <Text fontSize='16px' color='main_1' fontStyle='italic' >Start when your timer begins to count, Good Luck!</Text>
                    <VStack alignSelf='flex-start' alignItems='flex-start'>
                        <Text>Instructions:</Text>
                        <Text>- Don't reload or refresh this page, your answers won't be saved.
                        </Text>
                        <Text>- Answer all questions so you can submit your answers and finish the exam.</Text>
                        <Text>- If the timer ends before you answer all the questions, your answers will be saved and the window will be closed
                        </Text>
                    </VStack>

                    <VStack alignSelf='flex-start' alignItems='flex-start' pt='60px'>
                        <Text>Exam Guide:</Text>
                        <Text>
                            The color of the exam card could have one of these three cases:
                        </Text>
                        <Text>
                            1. White color means it's not answered yet.
                        </Text>
                        <Text>
                            2. Green color means it's answered, doesn't mean your answer is correct!.
                        </Text>
                        <Text>
                            3. Red color means you didn't answered the question and passed to the next
                        </Text>
                    </VStack>

                    <Button bg='main_1' color='white' w={{ base: '100%', lg: '30%' }}
                        onClick={() => window.location.pathname.includes('student') ? window.location.replace(`/student/solve-exam/${examId}?name=${parsed?.name}`) : window.location.replace(`/solve-exam/${examId}?name=${parsed?.name}`)
                        }
                    >Start your exam now</Button>
                </VStack>
            </Container>
        </Layout>
    )
}

export default ExameGuide