export default {
  //get all VIDEOs
  GET_VIDEOS_BY_CHAPTER_ID_REQUEST: "GET_VIDEOS_BY_CHAPTER_ID_REQUEST",
  GET_VIDEOS_BY_CHAPTER_ID_SUCCESS: "GET_VIDEOS_BY_CHAPTER_ID_SUCCESS",
  GET_VIDEOS_BY_CHAPTER_ID_FAIL: "GET_VIDEOS_BY_CHAPTER_ID_FAIL",

  GET_VIDEO_BY_ID_REQUEST: "GET_VIDEO_BY_ID_REQUEST",
  GET_VIDEO_BY_ID_SUCCESS: "GET_VIDEO_BY_ID_SUCCESS",
  GET_VIDEO_BY_ID_FAIL: "GET_VIDEO_BY_ID_FAIL",
  //edit VIDEOs
  UPDATE_SEEN_COUNT_REQUEST: "UPDATE_SEEN_COUNT_REQUEST",
  UPDATE_SEEN_COUNT_SUCCESS: "UPDATE_SEEN_COUNT_SUCCESS",
  UPDATE_SEEN_COUNT_FAIL: "UPDATE_SEEN_COUNT_FAIL",

  CREATE_SEEN_COUNT_REQUEST: "CREATE_SEEN_COUNT_REQUEST",
  CREATE_SEEN_COUNT_SUCCESS: "CREATE_SEEN_COUNT_SUCCESS",
  CREATE_SEEN_COUNT_FAIL: "CREATE_SEEN_COUNT_FAIL",

  ADD_VIDEO_VOUCHER_REQUEST: "ADD_VIDEO_VOUCHER_REQUEST",
  ADD_VIDEO_VOUCHER_SUCCESS: "ADD_VIDEO_VOUCHER_SUCCESS",
  ADD_VIDEO_VOUCHER_FAIL: "ADD_VIDEO_VOUCHER_FAIL",

  //create VIDEO
  GET_VIDEO_OTP_REQUEST: "GET_VIDEO_OTP_REQUEST",
  GET_VIDEO_OTP_SUCCESS: "GET_VIDEO_OTP_SUCCESS",
  GET_VIDEO_OTP_FAIL: "GET_VIDEO_OTP_FAIL",

  MORE_VIDEO_VIEWS_REQUEST: "MORE_VIDEO_VIEWS_REQUEST",
  MORE_VIDEO_VIEWS_SUCCESS: "MORE_VIDEO_VIEWS_SUCCESS",
  MORE_VIDEO_VIEWS_FAIL: "MORE_VIDEO_VIEWS_FAIL",
  //loading
  ADD_VIDEOS_LOADING: "ADD_VIDEOS_LOADING",
  VIDEO_SEEN_LOADING: "VIDEO_SEEN_LOADING",
  VIDEO_VOUCHER_LOADING: "VIDEO_VOUCHER_LOADING",

  VIDEO_DATA_REQUEST: "VIDEO_DATA_REQUEST",
  VIDEO_DATA_SUCCESS: "VIDEO_DATA_SUCCESS",
  VIDEO_DATA_FAIL: "VIDEO_DATA_FAIL",
  SET_VIDEO_OTP_USER_DATA_REQUEST: "SET_VIDEO_OTP_USER_DATA_REQUEST",
};
