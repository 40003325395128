import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

const MainModel = ({
  header,
  openVar,
  children,
  buttonTitle,
  buttonFunc,
  closeFunc,
  isLoading = false,
  extraButtons,
}) => {
  const { onClose } = useDisclosure();

  return (
    <Modal
      isOpen={openVar}
      onClose={onClose}
      closeOnOverlayClick={true}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent bg="white">
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter justifyContent="center" flexDirection="column">
          {extraButtons && extraButtons}
          {buttonTitle && (
            <Button
              isLoading={isLoading}
              onClick={() => buttonFunc()}
              color="white"
              bg="main_1"
              _focus={{ outline: "none" }}
              minW="200px"
              type="submit"
            >
              {buttonTitle}
            </Button>
          )}
          <Button
            onClick={closeFunc}
            color="main_1"
            bg="transparent"
            _hover={{ outline: "none", bg: "transparent" }}
            _focus={{ outline: "none", bg: "transparent" }}
            minW="200px"
          >
            الغاء
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MainModel;
