import React from "react";
import LoginForm from "../Auth/LoginForm";
import ProtectedRouter from "../Auth/ProtectedRouter";
import Home from "../../pages/home";
import Students from "../../pages/admin/students";
import Groups from "../../pages/admin/groups";
import Lessons from "../../pages/admin/lessons";
import Streaming from "../../pages/admin/streaming";
import Videos from "../../pages/admin/videos";
import Events from "../../pages/admin/events";
import StudentVideo from "../../pages/student/videos";
import VideosChaptersList from "../../pages/student/videos/chapters-list";
import ModelAnswersChaptersList from "../../pages/student/model-answer/chapters-list";
import StudentModelAnswer from "../../pages/student/model-answer";
import Teachers from "../../pages/admin/teachers";
import TeachersAssistant from "../../pages/admin/teachersAssistant";
import StudentHome from "../../pages/student/home";
import Chapters from "../../pages/admin/chapters";
import Lectures from "../../pages/admin/lectures";
import ScanCode from "../../pages/admin/scan-code";
import HomeWork from "../../pages/student/homework";
import Exams from "../../pages/admin/exams";
import ChaptersExamsList from "../../pages/student/exams/chapters-list";
import ChapterExams from "../../pages/student/exams/chapter-exams";
import ExameGuide from "../../pages/student/exams/exame-guide";
import SolveExam from "../../pages/student/exams/solve-exam";
import QuestionsBank from "../../pages/admin/questions-bank";
import ExamResult from "../../pages/student/exams/exam-result";
import Questions from "../../pages/student/questions";
import VideosMoreViewRequests from "../../pages/admin/videosMoreViewRequests";
import DownloadableReport from "../../pages/admin/reports/downloadable-report";
import AttendanceReport from "../../pages/admin/reports/attendance";
import ExamAttendanceReport from "../../pages/admin/reports/exam-attendance";
import ExamReport from "../../pages/admin/reports/exam";
import VideoReport from "../../pages/admin/reports/video-views";
import PrintableQuestions from "../../pages/admin/reports/printable-questions";
import GroupsReport from "../../pages/admin/reports/groups";
import RedoRequests from "../../pages/admin/redo-requests";
import StudentExamsList from "../../pages/admin/student-exams";
import QrCodeVideos from "../../pages/student/videos/qrcode-videos";
import UnpaiedReport from "../../pages/admin/reports/unpaid-report";
import PaiedReport from "../../pages/admin/reports/paid-report";

import Payment from "../../pages/admin/payment";
import StudentsPaymentList from "../../containers/admin/payment/paymentList";
import Homeworks from "../../pages/admin/homework";
import HomeworkChaptersList from "../../pages/student/homework/chapters-list";
import StudentHomeWork from "../../pages/student/homework";
import HomeworkAttendanceReport from "../../pages/admin/reports/homework-attendence";
import HomeworkAbsentReport from "../../pages/admin/reports/homework-absent";
import Tags from "../../pages/admin/tags";
import Playlists from "../../pages/admin/playlists";
import HomeworkUncommentReport from "../../pages/admin/reports/homework-uncomment";
import SectionsList from "../../pages/student/videos/sections-list";
import ExamsSectionsList from "../../pages/student/exams/sections-list";
import HomeworkSectionsList from "../../pages/student/homework/sections-list";
import Sections from "../../pages/admin/sections";
import StudyWithEnoughExams from "../../pages/student/studyWithEnough/exams-list";
import StudyWithEnoughSolveExam from "../../pages/student/studyWithEnough/solve-exam";
import VideoPreviewPage from "../../pages/student/studyWithEnough/videoPreview";
const Routers = () => {
  return (
    <>
      {/* public */}
      <ProtectedRouter path="/" element={<Home />}></ProtectedRouter>
      {/* auth */}
      <ProtectedRouter path="/login" element={<LoginForm />}></ProtectedRouter>
      {/* //dashboard */}

      <ProtectedRouter
        path="/dashboard/students"
        element={<Students />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/groups"
        element={<Groups />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/lessons"
        element={<Lessons />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/streaming"
        element={<Streaming />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/videos"
        element={<Videos />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/events"
        element={<Events />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/teachers"
        element={<Teachers />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/teachers-assistant"
        element={<TeachersAssistant />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/chapters"
        element={<Chapters />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/lectures"
        element={<Lectures />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/exams"
        element={<Exams />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/payments"
        element={<Payment />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/homework"
        element={<Homeworks />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/payment-list/:payment_id"
        element={<StudentsPaymentList />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/tags"
        element={<Tags />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/playlists"
        element={<Playlists />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/student-exams"
        element={<StudentExamsList />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/questions-bank"
        element={<QuestionsBank />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/videos-more-view-requests"
        element={<VideosMoreViewRequests />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/exam-redo-requests"
        element={<RedoRequests />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/downloaded-reports"
        element={<DownloadableReport />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/attendence-report"
        element={<AttendanceReport />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/exam-attendence-report"
        element={<ExamAttendanceReport />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/exam-report"
        element={<ExamReport />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/homework-attendence-report"
        element={<HomeworkAttendanceReport />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/homework-absent-report"
        element={<HomeworkAbsentReport />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/videos-report"
        element={<VideoReport />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/sections"
        element={<Sections />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/dashboard/groups-report"
        element={<GroupsReport />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/print-all-questions"
        element={<PrintableQuestions />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/unpaid-report"
        element={<UnpaiedReport />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/paid-report"
        element={<PaiedReport />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/dashboard/uncomment-homework-report"
        element={<HomeworkUncommentReport />}
      ></ProtectedRouter>

      {/* students */}
      <ProtectedRouter path="/home" element={<StudentHome />}></ProtectedRouter>
      <ProtectedRouter
        path="/videos/sections"
        element={<SectionsList />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/videos/chapters/:id"
        element={<VideosChaptersList />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/video/:id"
        element={<StudentVideo />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/model-answers/chapters"
        element={<ModelAnswersChaptersList />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/model-answer/:id"
        element={<StudentModelAnswer />}
      ></ProtectedRouter>
      <ProtectedRouter
        exact
        path="/homework/:id"
        element={<StudentHomeWork />}
      ></ProtectedRouter>
      <ProtectedRouter
        exact
        path="/homeworks/chapters/:id"
        element={<HomeworkChaptersList />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/homeworks/sections"
        element={<HomeworkSectionsList />}
      ></ProtectedRouter>
      <ProtectedRouter
        exact
        path="/scan-code/:id/:type"
        element={<ScanCode />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/exams/sections"
        element={<ExamsSectionsList />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/exams/chapters/:id"
        element={<ChaptersExamsList />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/exam/:id"
        element={<ChapterExams />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/student/exams"
        element={<ChapterExams />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/exam-guide/:id"
        element={<ExameGuide />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/student/exam-guide/:id"
        element={<ExameGuide />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/solve-exam/:id"
        element={<SolveExam />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/study-with-enough/video-preview/:id"
        element={<VideoPreviewPage />}
      ></ProtectedRouter>

      <ProtectedRouter
        path="/student/solve-exam/:id"
        element={<SolveExam />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/exam-result/:id"
        element={<ExamResult />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/questions"
        element={<Questions />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/video/qr-code/:video_id"
        element={<QrCodeVideos />}
      ></ProtectedRouter>
      <ProtectedRouter
        path="/study-with-enough/chapters"
        element={<StudyWithEnoughExams />}
        exact
      ></ProtectedRouter>

      <ProtectedRouter
        path="/study/:id"
        element={<StudyWithEnoughSolveExam />}
      ></ProtectedRouter>
    </>
  );
};

export default Routers;
