import React, { useEffect } from "react";
import { Box, Button, Circle, Heading, HStack, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  closeCreateExamDataModel,
  openQuestionsListModel,
} from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { getAllChaptersRequest } from "../../../../modules/admin-modules/chapters/Actions";
import {
  getAllGroupsRequest,
  getPerformanceGroupsRequest,
} from "../../../../modules/admin-modules/groups/Actions";
import {
  createExamRequest,
  editExamRequest,
} from "../../../../modules/admin-modules/exams/Actions";
import StepTwo from "../forms/step-two";
import StepOne from "../forms/step-one";
import { getAllVideosRequest } from "../../../../modules/admin-modules/videos/Actions";
const AddExamData = ({ isAdd, data, setSelectedData, setExamData }) => {
  const [isFormOne, setFormOne] = useState(true);

  const openExamData = useSelector((state) => state.model.isOpen15);
  const chapters = useSelector((state) => state.chapters.chapters);
  const groups = useSelector((state) => state.groups.groups);
  const isLoading = useSelector((state) => state.chapters.isLoading);

  const dispatch = useDispatch();
  useEffect(() => {
    if (openExamData) {
      if (chapters?.length == 0) dispatch(getAllChaptersRequest());
      if (groups?.length == 0) dispatch(getAllGroupsRequest());
      dispatch(getPerformanceGroupsRequest());
      dispatch(getAllVideosRequest());
    }
  }, [openExamData]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: data ? data : { time: "0" },
    shouldUnregister: true,
  });

  const watchSubType = watch("sub_type");
  const watchTotalQustions = watch("question_number");
  const watchChapters = watch("chapters");
  const watchTimer = watch("timer");
  const watchQuestionWay = watch("random");
  const watchType = watch("type");
  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? " إضافة المحتوي" : "تعديل  المحتوي"}
      </Heading>
    </Box>
  );

  const onSubmit = async (values) => {
    const totel = { ...data, ...values };

    let formData = new FormData();
    let chapters = [];
    for (let key in totel) {
      if (key == "groups") {
        totel[key]?.map((val, idx) =>
          formData.append(`groups[${idx}]`, val?.id ? val?.id : val)
        );
      } else if (key == "chapters") {
        totel[key]?.map((val, idx) => {
          if (val) chapters.push(val);
          formData.append(
            `chapters[${idx}][chapter_id]`,
            val.id ? val.id : val?.chapter_id
          );
          formData.append(
            `chapters[${idx}][no_of_questions]`,
            val.no_of_questions ? val.no_of_questions : totel?.question_number
          );
        });
      } else if (totel[key]) {
        formData.append(key, totel[key]);
      }
    }
    if (totel["timer"] == 0) {
      formData.append("time", 0);
    }
    const action = (data) => {
      dispatch(closeCreateExamDataModel());
      setSelectedData({});
      reset();
      setFormOne(true);

      setExamData({
        chapter_id: chapters?.[0]?.chapter_id,
        exam: data?.id,
        question_number: data?.question_number,
      });
      isAdd && watchQuestionWay == 0 && dispatch(openQuestionsListModel());
    };
    if (isAdd) {
      dispatch(createExamRequest(formData, action));
    } else {
      formData.append("_method", "PUT");

      dispatch(
        editExamRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeCreateExamDataModel());
    setSelectedData({});
    reset();
    setFormOne(true);
  };

  useEffect(() => {
    if (watchQuestionWay == 0) setValue("sub_type", 1);
  }, [watchQuestionWay]);
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openExamData}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
      extraButtons={
        <Button
          onClick={() => setFormOne(!isFormOne)}
          color="white"
          bg="main_1"
          _focus={{ outline: "none" }}
          minW="200px"
          my={2}
        >
          {isFormOne ? " الخطوة التالية" : "الخطوة السابقة"}
        </Button>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <HStack dir="rtl" w="100%" justifyContent="center" spacing="50px">
          <HStack onClick={() => setFormOne(true)} cursor="pointer">
            <Circle
              size="40px"
              bg={isFormOne ? "main_1" : "white"}
              color={isFormOne ? "white" : "main_1"}
              borderColor="main_1"
              border="1px solid"
            >
              1
            </Circle>
            <Text>معلومات عن الاختبار</Text>
          </HStack>
          <HStack onClick={() => setFormOne(false)} cursor="pointer">
            <Circle
              size="40px"
              bg={!isFormOne ? "main_1" : "white"}
              color={!isFormOne ? "white" : "main_1"}
              borderColor="main_1"
              border="1px solid"
            >
              2
            </Circle>
            <Text>أسئلة الاختبار</Text>
          </HStack>
        </HStack>

        {/* first form */}
        <StepOne
          data={data}
          register={register}
          watchTimer={watchTimer}
          errors={errors}
          isFormOne={isFormOne}
          watchType={watchType}
          setValue={setValue}
        />
        {/* form two */}
        <StepTwo
          watchSubType={watchSubType}
          watchTotalQustions={watchTotalQustions}
          watchQuestionWay={watchQuestionWay}
          data={data}
          setValue={setValue}
          register={register}
          errors={errors}
          isFormOne={isFormOne}
          watchChapters={watchChapters}
          isAdd={isAdd}
        />
      </form>
    </MainModel>
  );
};

export default AddExamData;
