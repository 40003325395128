import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { RiPrinterFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import YouTube from "react-youtube";
import Layout from "../../../components/dashboard/Layout";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { getAllChaptersRequest } from "../../../modules/admin-modules/chapters/Actions";
import { getAllQuestionsBankRequest } from "../../../modules/admin-modules/questions-bank/Actions";

const PrintableQuestions = () => {
  const dispatch = useDispatch();
  const [chapterId, setChapterId] = useState(false);
  const [withoutHint, setWithoutHint] = useState(false);

  const questionsBank = useSelector(
    (state) => state.questionsBank.questionsBank
  );
  const isLoading = useSelector((state) => state.questionsBank.isLoading);
  const chapters = useSelector((state) => state.chapters.chapters);

  const chaptersOptions = chapters?.map((data) => {
    return { value: data.id, label: data.name };
  });
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "white",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  useEffect(() => {
    if (chapters?.length == 0) dispatch(getAllChaptersRequest());
  }, [chapters]);

  useEffect(() => {
    if (chapterId) dispatch(getAllQuestionsBankRequest(chapterId));
  }, [chapterId]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const baseUrl = "https://enoughphysics.com/enoughapi";
  const opts = {
    height: "300",
    width: "300",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <Layout>
      <VStack p="20px" height="100vh">
        <Flex
          w="100%"
          my="30px"
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          zIndex="10000000"
        >
          <Text fontSize="24px" fontWeight="600" color="main_1">
            الاسئلة
          </Text>
          <Flex w="50%" alignItems="center" gap={2}>
            <Box w="40%">
              <Select
                options={chaptersOptions}
                onChange={(data) => setChapterId(data?.value)}
                styles={colourStyles}
                placeholder="الفصل"
              />
            </Box>
            <Box w="40%">
              <Select
                options={[
                  { value: "all", label: "All" },
                  { value: "hint", label: "Without hint" },
                ]}
                onChange={(data) => {
                  if (data?.value == "all") setWithoutHint(false);
                  else setWithoutHint(true);
                }}
                styles={colourStyles}
                placeholder="الفصل"
              />
            </Box>
            <Button
              color="white"
              bg="main_1"
              mx="5px"
              onClick={handlePrint}
              rightIcon={<RiPrinterFill />}
            >
              Print
            </Button>

            <LogoutMenu />
          </Flex>
        </Flex>

        <VStack
          ref={componentRef}
          spacing={"0"}
          alignItems="flex-end"
          w="100%"
          p="20px"
          my="10px"
          border="1px solid #80808045"
          borderRadius="8px"
        >
          {isLoading ? (
            <Text>.....Loading</Text>
          ) : withoutHint ? (
            questionsBank?.map((val, idx) => {
              if (val?.model_answer_image == null)
                return (
                  <VStack key={idx} w="100%" alignItems="flex-end" h="525px">
                    <HStack w="100%">
                      <VStack width="10%" h="120px">
                        {val?.book_marked == 1 && (
                          <Text bg="yellow" borderRadius="8px" p="5px">
                            {"Bookmarked"}
                          </Text>
                        )}
                        <Text bg="green.200" borderRadius="8px" p="5px">
                          {" "}
                          {val?.deleted_at != null ? "Suspended " : "Opend"}
                        </Text>

                        {val?.model_answer_image && (
                          <Text bg="yellow.200" borderRadius="8px" p="5px">
                            with hint
                          </Text>
                        )}
                      </VStack>
                      <Box width="20%" h="120px">
                        {val?.image && (
                          <Image
                            src={`${baseUrl}/${val?.image}`}
                            h="100%"
                            w="100%"
                          />
                        )}
                      </Box>
                      <VStack w="65%" alignItems="flex-end">
                        {val?.comprehension && (
                          <Text>Comprehension : {val?.comprehension}</Text>
                        )}
                        <Text>Question : {val?.question}</Text>
                      </VStack>
                      <Text w="5%"> - {idx + 1} </Text>
                    </HStack>
                    <VStack w="100%" alignItems="flex-end" px="100px">
                      {val?.answers?.map((answer, index) => {
                        return (
                          <VStack>
                            <Text
                              textAlign="left"
                              padding="5px"
                              w="100%"
                              borderRadius="8px"
                              bg={
                                answer?.right_answer == 1
                                  ? "green.200"
                                  : "transparent"
                              }
                              color={
                                answer?.right_answer == 1 ? "white" : "black"
                              }
                            >
                              {" "}
                              {answer?.answer}{" "}
                            </Text>
                          </VStack>
                        );
                      })}
                    </VStack>
                  </VStack>
                );
            })
          ) : (
            questionsBank?.map((val, idx) => {
              const videoData = val?.url?.split("/");
              const splitLength = val?.url?.split("/")?.length;
              const videoId = videoData?.[splitLength - 1];
              return (
                <VStack key={idx} w="100%" alignItems="flex-end" h="725px">
                  <HStack w="100%">
                    <VStack width="10%" h="120px">
                      {val?.book_marked == 1 && (
                        <Text bg="yellow" borderRadius="8px" p="5px">
                          {"Bookmarked"}
                        </Text>
                      )}
                      <Text bg="green.200" borderRadius="8px" p="5px">
                        {" "}
                        {val?.deleted_at != null ? "Suspended " : "Opend"}
                      </Text>

                      {val?.model_answer_image && (
                        <Text bg="yellow.200" borderRadius="8px" p="5px">
                          with hint
                        </Text>
                      )}
                    </VStack>
                    <Box width="20%" h="120px">
                      {val?.image && (
                        <Image
                          src={`${baseUrl}/${val?.image}`}
                          h="100%"
                          w="100%"
                        />
                      )}
                    </Box>
                    <VStack w="65%" alignItems="flex-end">
                      {val?.comprehension && (
                        <Text>Comprehension : {val?.comprehension}</Text>
                      )}
                      <Text>Question : {val?.question}</Text>
                    </VStack>
                    <Text w="5%"> - {idx + 1} </Text>
                  </HStack>
                  <VStack w="100%" alignItems="flex-end" px="100px">
                    {val?.answers?.map((answer, index) => {
                      return (
                        <Text
                          textAlign="left"
                          padding="5px"
                          w="100%"
                          borderRadius="8px"
                          bg={
                            answer?.right_answer == 1
                              ? "green.200"
                              : "transparent"
                          }
                          color={answer?.right_answer == 1 ? "white" : "black"}
                        >
                          {" "}
                          {answer?.answer}{" "}
                        </Text>
                      );
                    })}
                  </VStack>

                  <HStack>
                    {val?.model_answer_image &&
                      val?.model_answer_image != "null" && (
                        <Image
                          src={`${baseUrl}/${val?.model_answer_image}`}
                          w="300px"
                        />
                      )}
                    {videoId && (
                      <YouTube
                        videoId={videoId}
                        opts={opts}
                        onReady={onReady}
                      />
                    )}
                    {val?.model_answer_image &&
                      val?.model_answer_image != "null" &&
                      val?.url && <Text>: Hint </Text>}
                  </HStack>
                </VStack>
              );
            })
          )}
        </VStack>
      </VStack>
    </Layout>
  );
};

export default PrintableQuestions;
