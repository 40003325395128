import { Spinner, Flex } from "@chakra-ui/react";
import React from "react";

const Loading = () => {
  return (
    <Flex
      position={"fixed"}
      w="100%"
      h="100%"
      top="-8px"
      left="0"
      alignItems={"center"}
      opacity="0.4"
      justifyContent={"center"}
      bg="main_1"
      zIndex={"1000"}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  );
};

export default Loading;
