import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getVideosByChapterID({ payload }) {
  try {
    yield put(actions.addVideosLoading(true));
    const result = yield call(api.getVideosByChapterID, payload);
    yield put(actions.getVideosByChapterIdSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, "error");
    yield put(actions.getVideosByChapterIdFail());
  } finally {
    yield put(actions.addVideosLoading(false));
  }
}

function* getVideoByData({ payload }) {
  try {
    yield put(actions.addVideosLoading(true));
    const result = yield call(api.getVideoByID, payload);
    yield put(actions.getVideoByIdSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, "error");
    yield put(actions.getVideoByIdFail());
  } finally {
    yield put(actions.addVideosLoading(false));
  }
}

//Edit Tables
function* getVideoOtp({ payload }) {
  try {
    yield put(actions.addVideosLoading(true));
    console.log(payload, "payload");

    const result = yield call(api.getOtp, payload);
    console.log(result, payload);
    yield put(actions.getVideoOtpSuccess(result.data));

    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.getVideoOtpFail());
  } finally {
    yield put(actions.addVideosLoading(false));
  }
}

// create
function* updateSeenCount({ payload }) {
  try {
    yield put(actions.videoSeenLoading(true));
    const result = yield call(api.updateSeenCount, payload);
    yield put(actions.updateSeenCountSuccess({ payload, result }));
  } catch (error) {
    yield put(actions.updateSeenCountFail());
  } finally {
    yield put(actions.videoSeenLoading(false));
  }
}
function* createSeenCount({ payload }) {
  try {
    yield put(actions.videoSeenLoading(true));
    const result = yield call(api.createSeenCount, payload);
    yield put(actions.createSeenCountSuccess({ payload, result }));
  } catch (error) {
    yield put(actions.createSeenCountFail());
  } finally {
    yield put(actions.videoSeenLoading(false));
  }
}
function* videoData({ payload }) {
  try {
    yield put(actions.videoSeenLoading(true));
    const result = yield call(api.videoData, payload);
    yield put(actions.videoDataSuccess({ payload, result }));
  } catch (error) {
    yield put(actions.videoDataFail());
  } finally {
    yield put(actions.videoSeenLoading(false));
  }
}
function* addVoucher({ payload }) {
  try {
    yield put(actions.videoVoucherLoading(true));
    const result = yield call(api.addVoucher, payload?.formData?.formData);
    toast.success("طلبك  مقبول");
    window.location.reload();
  } catch (error) {
    toast.error("طلبك  مرفوض");
    yield put(actions.addVoucherFail());
  } finally {
    yield put(actions.videoVoucherLoading(false));
  }
}

function* moreVideoSeen({ payload }) {
  try {
    // yield put(actions.videoSeenLoading(true));
    const result = yield call(api.moreVideoViews, payload);
    yield put(actions.moreVideoViewsSuccess({ payload, result }));
    toast.success("طلبك قيد المراجعة");
    window.open("https://wa.me/201025830160", "_blank");
  } catch (error) {
    yield put(actions.moreVideoViewsFail());
    toast.success("طلبك قيد المراجعة");
    window.open("https://wa.me/201025830160", "_blank");
  } finally {
    // yield put(actions.videoSeenLoading(false));
  }
}
export default function* chapterVideoSaga() {
  yield takeLatest(
    Types.GET_VIDEOS_BY_CHAPTER_ID_REQUEST,
    getVideosByChapterID
  );
  yield takeLatest(Types.GET_VIDEO_BY_ID_REQUEST, getVideoByData);

  yield takeLatest(Types.GET_VIDEO_OTP_REQUEST, getVideoOtp);
  yield takeLatest(Types.UPDATE_SEEN_COUNT_REQUEST, updateSeenCount);
  yield takeLatest(Types.CREATE_SEEN_COUNT_REQUEST, createSeenCount);
  yield takeLatest(Types.MORE_VIDEO_VIEWS_REQUEST, moreVideoSeen);
  yield takeLatest(Types.ADD_VIDEO_VOUCHER_REQUEST, addVoucher);
  yield takeLatest(Types.VIDEO_DATA_REQUEST, videoData);
}
