import logo from "./logo.svg";
import "./App.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { store } from "./services/configurationStore";
import { ChakraProvider } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { theme } from "./chakra.config";
import Routers from "./components/Routes";
import { getUserDataRequest } from "./modules/user/Actions";
import { useEffect } from "react";
import Cookies from "js-cookie";

function App() {
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("token");
  useEffect(() => {
    if (token) dispatch(getUserDataRequest());
  }, [token]);
  return (
    <ChakraProvider theme={theme}>
      <ToastContainer />
      <Routers />
    </ChakraProvider>
  );
}

export default App;
