import { Flex, Image, Text, Box } from '@chakra-ui/react'
import React from 'react'

const ServiceCard = ({ imageUrl, title, description }) => {
    return (

        <div class="card middle">
            <div class="front">
                <Flex flexDirection={'column'} alignItems='center' justifyContent={'center'} >
                    <Text fontSize='27px'
                        my='10px'
                        fontWeight='500'>{title}</Text>
                    <Image src={require(`../../../assets/${imageUrl}.webp`)} alt="" w='130px' h='130px' />
                </Flex>

            </div>
            <div class="back">
                <div class="back-content middle">
                    <h2>DarkCode</h2>
                    <span>Youtube Channel</span>
                    <div class="sm">
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                        <a href="#"><i class="fab fa-twitter"></i></a>
                        <a href="#"><i class="fab fa-youtube"></i></a>
                        <a href="#"><i class="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ServiceCard