export default {
  //get all HOMEWORKs
  GET_HOMEWORKS_BY_CHAPTER_ID_REQUEST: "GET_HOMEWORKS_BY_CHAPTER_ID_REQUEST",
  GET_HOMEWORKS_BY_CHAPTER_ID_SUCCESS: "GET_HOMEWORKS_BY_CHAPTER_ID_SUCCESS",
  GET_HOMEWORKS_BY_CHAPTER_ID_FAIL: "GET_HOMEWORKS_BY_CHAPTER_ID_FAIL",

  GET_HOMEWORK_BY_ID_REQUEST: "GET_HOMEWORK_BY_ID_REQUEST",
  GET_HOMEWORK_BY_ID_SUCCESS: "GET_HOMEWORK_BY_ID_SUCCESS",
  GET_HOMEWORK_BY_ID_FAIL: "GET_HOMEWORK_BY_ID_FAIL",
  //edit HOMEWORKs
  UPDATE_SEEN_COUNT_REQUEST: "UPDATE_SEEN_COUNT_REQUEST",
  UPDATE_SEEN_COUNT_SUCCESS: "UPDATE_SEEN_COUNT_SUCCESS",
  UPDATE_SEEN_COUNT_FAIL: "UPDATE_SEEN_COUNT_FAIL",

  SOLVE_HOMEWORK_REQUEST: "SOLVE_HOMEWORK_REQUEST",
  SOLVE_HOMEWORK_SUCCESS: "SOLVE_HOMEWORK_SUCCESS",
  SOLVE_HOMEWORK_FAIL: "SOLVE_HOMEWORK_FAIL",

  ADD_VOUCHER_REQUEST: "ADD_VOUCHER_REQUEST",
  ADD_VOUCHER_SUCCESS: "ADD_VOUCHER_SUCCESS",
  ADD_VOUCHER_FAIL: "ADD_VOUCHER_FAIL",

  //create HOMEWORK
  GET_HOMEWORK_OTP_REQUEST: "GET_HOMEWORK_OTP_REQUEST",
  GET_HOMEWORK_OTP_SUCCESS: "GET_HOMEWORK_OTP_SUCCESS",
  GET_HOMEWORK_OTP_FAIL: "GET_HOMEWORK_OTP_FAIL",

  MORE_HOMEWORK_VIEWS_REQUEST: "MORE_HOMEWORK_VIEWS_REQUEST",
  MORE_HOMEWORK_VIEWS_SUCCESS: "MORE_HOMEWORK_VIEWS_SUCCESS",
  MORE_HOMEWORK_VIEWS_FAIL: "MORE_HOMEWORK_VIEWS_FAIL",
  //loading
  ADD_HOMEWORKS_LOADING: "ADD_HOMEWORKS_LOADING",
  HOMEWORK_SEEN_LOADING: "HOMEWORK_SEEN_LOADING",
  HOMEWORK_VOUCHER_LOADING: "HOMEWORK_VOUCHER_LOADING",
};
