import { IconButton, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiDeleteBinLine, RiFileEditLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CTable from "../../../components/Table/table";
import {
  deleteExamRequest,
  enableExamRequest,
} from "../../../modules/admin-modules/exams/Actions";
import { deleteHomeworkRequest } from "../../../modules/admin-modules/homework/Actions";
import {
  openAddHomeworkModel,
  openCreateEvent,
  openCreateExamDataModel,
  openDeleteHomeworkModel,
} from "../../../modules/admin-modules/models/Actions";

const HomeworkTable = ({ setAdd, setSelectedData }) => {
  const dispatch = useDispatch();

  const homeworks = useSelector((state) => state.homework.homeworks);
  const isLoading = useSelector((state) => state.homework.isLoading);

  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });

  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const Data = homeworks?.map((data) => {
    return {
      ...data,
      id: data?.id,
      title: data?.name,
      video: data?.video_id,
      video_id: data?.video_id?.id,
      chapter: data?.chapter_id,
      chapter_id: data?.chapter_id?.id,
      group: data?.groups?.map((group) => {
        return <Text>{group?.name}</Text>;
      }),
      edit: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiFileEditLine />}
          onClick={() => {
            setAdd(false);
            dispatch(openAddHomeworkModel());
          }}
        />
      ),
      delete: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiDeleteBinLine color="red" />}
          onClick={() => dispatch(openDeleteHomeworkModel(data?.id))}
        />
      ),
    };
  });
  const columns = [
    {
      Header: " الواجب",
      accessor: "title",
    },
    {
      Header: "الفصل",
      accessor: "chapter.name_ar",
    },

    {
      Header: "الفيديو",
      accessor: "video.title",
    },
    {
      Header: "المجموعات",
      accessor: "group",
    },
    {
      Header: "تعديل",
      accessor: "edit",
    },
    {
      Header: "حذف",
      accessor: "delete",
    },
  ];

  return (
    <CTable
      selectedData={viewData}
      Columns={columns}
      Data={Data}
      path={"openModel"}
      Actions
      Title={``}
      subTitle=""
      btnTitle=""
      placeHolder=""
      noSearchBar={true}
      noFilter={true}
      footerBtnTitle
      filterList
      addButton
      addButtonTitle=""
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={pageNumber}
      setPageNumber={setPageNumber}
      noSecondSearchBar={true}
      isLoading={isLoading}
      perPage={size}
      totalPage={Math.ceil(homeworks?.length / 10)}
    />
  );
};

export default HomeworkTable;
