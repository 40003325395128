import Types from "./Types";

const INITIAL_STATE = {
  homeworks: [],
  studenthomeworks: [],
  exam: {},
  isLoading: false,
  publishLoading: false,
  restrictLoading: false,
  enableLoading: false,
  count: "",
};

export default function homeworks(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_HOMEWORKS_SUCCESS: {
      return {
        ...state,
        homeworks: payload.data,
        count: payload.data.length,
      };
    }

    case Types.GET_HOMEWORKS_SUPER_ADMIN_SUCCESS: {
      return {
        ...state,
        homeworks: payload.data,
        count: payload.data.length,
      };
    }
    case Types.GET_ADMIN_STUDENT_HOMEWORKS_SUCCESS: {
      return {
        ...state,
        studenthomeworks: payload.data,
        count: payload.data.length,
      };
    }

    case Types.RESTRICT_HOMEWORK_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ENABLE_HOMEWORK_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_HOMEWORK_SUCCESS: {
      return {
        ...state,
        exam: payload,
      };
    }

    case Types.EDIT_HOMEWORK_SUCCESS: {
      let data = payload.data;
      const homeworks = state.homeworks;
      let idx = 0;
      homeworks.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      homeworks[idx] = data;
      return {
        ...state,
        homeworks: homeworks,
      };
    }

    // create
    case Types.CREATE_HOMEWORK_SUCCESS: {
      const data = payload.data.data;
      let newArray = state.homeworks.slice();
      let index = state.homeworks.length + 1;
      newArray.splice(index, 0, data);
      return {
        ...state,
        homeworks: newArray,
      };
    }
    case Types.ADD_HOMEWORKS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.DELETE_HOMEWORK_SUCCESS: {
      const newhomeworks = state.homeworks.filter(
        (item) => item.id !== payload
      );
      return {
        ...state,
        homeworks: newhomeworks,
      };
    }

    case Types.FILTER_HOMEWORK_SUCCESS: {
      return {
        ...state,
        homeworks: payload.data,
        count: payload.data.length,
      };
    }

    case Types.PUBLISH_HOMEWORK_LOADING: {
      return {
        ...state,
        publishLoading: payload,
      };
    }
    case Types.RESTRICT_HOMEWORK_LOADING: {
      return {
        ...state,
        restrictLoading: payload,
      };
    }

    case Types.ENABLE_HOMEWORK_LOADING: {
      return {
        ...state,
        enableLoading: payload,
      };
    }

    case Types.PUBLISH_HOMEWORK_SUCCESS: {
      let id = payload.payload;
      let data = payload.result.data.data;
      const homeworks = state.homeworks;
      let idx = 0;
      homeworks.forEach((table, index) => {
        if (table.id === id) {
          idx = index;
          return;
        }
      });

      homeworks[idx].auto_publish_result = data?.auto_publish_result;
      return {
        ...state,
        homeworks: homeworks,
      };
    }
    case Types.EDIT_HOMEWORK_QUESTION_SUCCESS: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
}
