import {
  Box,
  Flex,
  HStack,
  Icon,
  Square,
  Text,
  VStack,
  useEditable,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiDownload2Line } from "react-icons/ri";
import { useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "../../../helpers/api";
import VideoPreview from "../../../containers/student/video/videoPreview";
import Layout from "../../../components/student/Layout/layout";

const VideoPreviewPage = () => {
  const [loading, setLoading] = useState(true);
  const [otp, setOtp] = useState("");
  const [playBack, setPlayBack] = useState("");
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedData, setSelectedData] = useState({});
  const baseUrl = "https://enoughphysics.com/enoughapi";

  const getVideoData = async (id) => {
    const res = await axiosInstance.get(`/students/videos/otp/${id}`);

    await setOtp(res?.data?.otp);
    await setPlayBack(res?.data?.playbackInfo);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    if (id) getVideoData(id);
  }, [id]);
  return (
    <Layout color="main_1" bg="white" isLoading={loading}>
      <VStack
        w="100%"
        h="100%"
        spacing="40px"
        m="0 !important"
        my="80px !important"
        alignItems="center"
        justify="center"
      >
        <Box w={{ base: "100%", md: "50%" }}>
          <VideoPreview loading={loading} otp={otp} playBack={playBack} />
        </Box>

        {/* video description */}
      </VStack>
    </Layout>
  );
};

export default VideoPreviewPage;
