import {
  createSeenCountRequest,
  updateSeenCountRequest,
} from "../../../modules/students-modules/videos/Actions";

export const countSeens = async (dispatch, data) => {
  const formData = !data?.views_count?.views_count
    ? {
        video_id: data?.id,
        seen: 1,
        views_count: 1,
      }
    : {
        video_id: data?.id,
      };

  console.log(data);
  if (!data?.views_count) {
    await dispatch(createSeenCountRequest(formData));
  } else {
    if (data?.views_count?.views_count <= data.max_allowed_views) {
      await dispatch(updateSeenCountRequest(formData));
    }
  }
};
