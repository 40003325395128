import Types from "./Types";

export const getHomeworksByChapterIdRequest = (id) => ({
  type: Types.GET_HOMEWORKS_BY_CHAPTER_ID_REQUEST,
  payload: id,
});

export const getHomeworksByChapterIdSuccess = (data) => ({
  type: Types.GET_HOMEWORKS_BY_CHAPTER_ID_SUCCESS,
  payload: data,
});

export const getHomeworksByChapterIdFail = () => ({
  type: Types.GET_HOMEWORKS_BY_CHAPTER_ID_FAIL,
});

export const getHomeworkByIdRequest = (id) => ({
  type: Types.GET_HOMEWORK_BY_ID_REQUEST,
  payload: id,
});

export const getHomeworkByIdSuccess = (data) => ({
  type: Types.GET_HOMEWORK_BY_ID_SUCCESS,
  payload: data,
});

export const getHomeworkByIdFail = () => ({
  type: Types.GET_HOMEWORK_BY_ID_FAIL,
});

export const solveHomeworkRequest = (data) => ({
  type: Types.SOLVE_HOMEWORK_REQUEST,
  payload: data,
});

export const solveHomeworkSuccess = (data) => ({
  type: Types.SOLVE_HOMEWORK_SUCCESS,
  payload: data,
});

export const solveHomeworkFail = () => ({
  type: Types.SOLVE_HOMEWORK_FAIL,
});

export const addVoucherRequest = (body) => ({
  type: Types.ADD_VOUCHER_REQUEST,
  payload: body,
});

export const addVoucherSuccess = (data) => ({
  type: Types.ADD_VOUCHER_SUCCESS,
  payload: data,
});

export const addVoucherFail = () => ({
  type: Types.ADD_VOUCHER_FAIL,
});

export const updateSeenCountRequest = (body) => ({
  type: Types.UPDATE_SEEN_COUNT_REQUEST,
  payload: body,
});

export const updateSeenCountSuccess = (data) => ({
  type: Types.UPDATE_SEEN_COUNT_SUCCESS,
  payload: data,
});

export const updateSeenCountFail = () => ({
  type: Types.UPDATE_SEEN_COUNT_FAIL,
});

export const getHomeworkOtpRequest = (body) => ({
  type: Types.GET_HOMEWORK_OTP_REQUEST,
  payload: body,
});

export const getHomeworkOtpSuccess = (data) => ({
  type: Types.GET_HOMEWORK_OTP_SUCCESS,
  payload: data,
});

export const getHomeworkOtpFail = () => ({
  type: Types.GET_HOMEWORK_OTP_FAIL,
});

export const moreHomeworkViewsRequest = (body) => ({
  type: Types.MORE_HOMEWORK_VIEWS_REQUEST,
  payload: body,
});

export const moreHomeworkViewsSuccess = (data) => ({
  type: Types.MORE_HOMEWORK_VIEWS_SUCCESS,
  payload: data,
});

export const moreHomeworkViewsFail = () => ({
  type: Types.MORE_HOMEWORK_VIEWS_FAIL,
});

//LOADING
export const addHomeworksLoading = (isLoading) => ({
  type: Types.ADD_HOMEWORKS_LOADING,
  payload: isLoading,
});

//LOADING
export const HomeworkSeenLoading = (isLoading) => ({
  type: Types.Homework_SEEN_LOADING,
  payload: isLoading,
});

//LOADING
export const HomeworkVoucherLoading = (isLoading) => ({
  type: Types.Homework_VOUCHER_LOADING,
  payload: isLoading,
});
