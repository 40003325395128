import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddHomework from "../../../containers/admin/homework/models/add-homework";
import SearchBar from "../../../containers/admin/homework/searchbar";
import HomeworkTable from "../../../containers/admin/homework/table";
import DeleteHomework from "../../../containers/admin/homework/models/delete-homework";

import {
  filterHomeworkRequest,
  getAllHomeworksRequest,
} from "../../../modules/admin-modules/homework/Actions";
import { getAllGroupsRequest } from "../../../modules/admin-modules/groups/Actions";

const Homeworks = () => {
  const dispatch = useDispatch();
  const [isAdd, setAdd] = useState(false);

  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllHomeworksRequest());
  }, []);
  return (
    <Layout>
      <SearchBar setAdd={setAdd} />
      <AddHomework
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
      <HomeworkTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <DeleteHomework data={selectedData} />
    </Layout>
  );
};

export default Homeworks;
