import { Box, Circle, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import React from 'react';
import { RiArrowRightSLine, RiArrowLeftSLine } from 'react-icons/ri';

const CFooter = ({
    pageIndex,
    pageOptions,
    footerBtnTitle,
    setPage,
    totalPage,
    currentpage,
    setPageNumber
}) => {
    return (
        <Flex justify="center" align="center" flexDir="row-reverse">
            <Box mb="6">
                <Flex mt="2" alignItems='center'>


                    <IconButton
                        mr="1"
                        ml="1"
                        aria-label="previousPage"
                        fontSize='20px'
                        bg='transparent'
                        onClick={() => {
                            setPage((s) => (s === 0 ? 0 : s - 10));
                            setPageNumber((s) => (s === 0 ? 0 : s - 1));
                        }}
                        disabled={pageIndex === 0}
                        icon={
                            <RiArrowRightSLine
                                style={{
                                    color: '#690000'
                                }}
                            />
                        }
                    />
                    <HStack spacing='10px' maxW='100px' className='scrollX'>
                        {
                            [...Array(pageOptions.length)]?.map((data, index) => {
                                return <Circle cursor='pointer' size='20px' bg={currentpage == index ? '#e4e4e4' : 'transparent'}
                                    color={currentpage == index ? 'black' : '#690000'} onClick={(() => {
                                        setPageNumber((s) => index);
                                    })}>
                                    {index + 1}
                                </Circle>
                            })
                        }

                    </HStack>

                    <IconButton
                        mr="1"
                        ml="1"
                        aria-label="nextPage"
                        fontSize='20px'
                        bg='transparent'
                        onClick={() => {
                            setPage((s) => s + 10);
                            setPageNumber((s) => s + 1);
                        }}
                        disabled={pageIndex === totalPage - 1}
                        icon={
                            <RiArrowLeftSLine
                                style={{
                                    color: '#690000'
                                }}
                            />
                        }
                    />
                </Flex>
            </Box >
        </Flex >
    );
};

export default CFooter;
