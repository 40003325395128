import { Button, IconButton, Switch } from "@chakra-ui/react";
import React, { useState } from "react";
import { RiDeleteBinLine, RiFileEditLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CTable from "../../../components/Table/table";
import {
  openCreateEvent,
  openCreatePaymentModel,
} from "../../../modules/admin-modules/models/Actions";
import {
  deletePaymentRequest,
  editPaymentRequest,
} from "../../../modules/admin-modules/payment/Actions";

const PaymentTable = ({ setAdd, setSelectedData }) => {
  const navigate = useNavigate();
  const students = useSelector((state) => state.students.students);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.students.isLoading);
  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const Data = students?.map((data, index) => {
    return {
      ...data,
      index: `#${index + 1}`,
      id: data?.id,
      student_name: data?.name,

      group_name: data?.group?.name,

      edit: (
        <Button
          variant="outline"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          onClick={() => {
            setAdd(false);
            dispatch(openCreatePaymentModel());
          }}
        >
          الدفع
        </Button>
      ),

      delete: (
        <Button
          variant="outline"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          onClick={() => {
            setAdd(true);
            dispatch(openCreatePaymentModel());
          }}
        >
          فتح الفيديوهات
        </Button>
      ),
    };
  });
  const columns = [
    {
      Header: "رقم الطالب",
      accessor: "index",
    },
    {
      Header: "اسم الطالب",
      accessor: "student_name",
    },
    {
      Header: " المجموعة",
      accessor: "group_name",
    },

    {
      Header: "الدفع",
      accessor: "edit",
    },
    {
      Header: "حذف",
      accessor: "delete",
    },
  ];

  return (
    <CTable
      selectedData={viewData}
      Columns={columns}
      Data={Data}
      path={"openModel"}
      Actions
      Title={``}
      subTitle=""
      btnTitle=""
      placeHolder=""
      noSearchBar={true}
      noFilter={true}
      footerBtnTitle
      filterList
      addButton
      addButtonTitle=""
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={pageNumber}
      setPageNumber={setPageNumber}
      noSecondSearchBar={true}
      isLoading={isLoading}
      perPage={size}
      totalPage={Math.ceil(students?.length / 10)}
    />
  );
};

export default PaymentTable;
