import Types from "./Types";

export const getVideosByChapterIdRequest = (id) => ({
  type: Types.GET_VIDEOS_BY_CHAPTER_ID_REQUEST,
  payload: id,
});

export const getVideosByChapterIdSuccess = (data) => ({
  type: Types.GET_VIDEOS_BY_CHAPTER_ID_SUCCESS,
  payload: data,
});

export const getVideosByChapterIdFail = () => ({
  type: Types.GET_VIDEOS_BY_CHAPTER_ID_FAIL,
});

export const getVideoByIdRequest = (id) => ({
  type: Types.GET_VIDEO_BY_ID_REQUEST,
  payload: id,
});

export const getVideoByIdSuccess = (data) => ({
  type: Types.GET_VIDEO_BY_ID_SUCCESS,
  payload: data,
});

export const getVideoByIdFail = () => ({
  type: Types.GET_VIDEO_BY_ID_FAIL,
});

export const createSeenCountRequest = (body) => ({
  type: Types.CREATE_SEEN_COUNT_REQUEST,
  payload: body,
});

export const createSeenCountSuccess = (data) => ({
  type: Types.CREATE_SEEN_COUNT_SUCCESS,
  payload: data,
});

export const createSeenCountFail = () => ({
  type: Types.CREATE_SEEN_COUNT_FAIL,
});

export const addVoucherRequest = (body) => ({
  type: Types.ADD_VIDEO_VOUCHER_REQUEST,
  payload: body,
});

export const addVoucherSuccess = (data) => ({
  type: Types.ADD_VIDEO_VOUCHER_SUCCESS,
  payload: data,
});

export const addVoucherFail = () => ({
  type: Types.ADD_VIDEO_VOUCHER_FAIL,
});

export const updateSeenCountRequest = (body) => ({
  type: Types.UPDATE_SEEN_COUNT_REQUEST,
  payload: body,
});

export const updateSeenCountSuccess = (data) => ({
  type: Types.UPDATE_SEEN_COUNT_SUCCESS,
  payload: data,
});

export const updateSeenCountFail = () => ({
  type: Types.UPDATE_SEEN_COUNT_FAIL,
});
export const setVideoOtpUserDataRequest = (body) => ({
  type: Types.SET_VIDEO_OTP_USER_DATA_REQUEST,
  payload: body,
});
export const getVideoOtpRequest = (body) => ({
  type: Types.GET_VIDEO_OTP_REQUEST,
  payload: body,
});

export const getVideoOtpSuccess = (data) => ({
  type: Types.GET_VIDEO_OTP_SUCCESS,
  payload: data,
});

export const getVideoOtpFail = () => ({
  type: Types.GET_VIDEO_OTP_FAIL,
});

export const moreVideoViewsRequest = (body) => ({
  type: Types.MORE_VIDEO_VIEWS_REQUEST,
  payload: body,
});

export const moreVideoViewsSuccess = (data) => ({
  type: Types.MORE_VIDEO_VIEWS_SUCCESS,
  payload: data,
});

export const moreVideoViewsFail = () => ({
  type: Types.MORE_VIDEO_VIEWS_FAIL,
});

//LOADING
export const addVideosLoading = (isLoading) => ({
  type: Types.ADD_VIDEOS_LOADING,
  payload: isLoading,
});

//LOADING
export const videoSeenLoading = (isLoading) => ({
  type: Types.VIDEO_SEEN_LOADING,
  payload: isLoading,
});

//LOADING
export const videoVoucherLoading = (isLoading) => ({
  type: Types.VIDEO_VOUCHER_LOADING,
  payload: isLoading,
});

export const videoDataRequest = (body) => ({
  type: Types.VIDEO_DATA_REQUEST,
  payload: body,
});

export const videoDataSuccess = (data) => ({
  type: Types.VIDEO_DATA_SUCCESS,
  payload: data,
});

export const videoDataFail = () => ({
  type: Types.VIDEO_DATA_FAIL,
});
