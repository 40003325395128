import axiosInstance from "../../../helpers/api";

export const getHomeworsByChapterID = (id) => {
  return axiosInstance.get(`/students/student_homeworks/${id}`);
};

export const getHomeworkByID = (id) => {
  return axiosInstance.get(`/students/student_homeworks/${id}`);
};

export const getOtp = (id) => {
  return axiosInstance.get(`/students/student_homeworks/otp/${id}`);
};

export const updateSeenCount = (body) => {
  return axiosInstance.put(
    `/students/user_student_homeworks/${body?.Homework_id}`
  );
};

export const solveHomework = (data) => {
  {
  }
  return axiosInstance.post(
    `/students/student_homeworks/submitHomework`,
    data?.formData
  );
};
export const addVoucher = (body) => {
  return axiosInstance.post(`/students/Homework_requests/submitVoucher`, body);
};
export const moreHomeworkViews = (body) => {
  return axiosInstance.post(`/students/Homework_requests`, body);
};
